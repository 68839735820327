


























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";

@Component({
  components: {
    "simple-confirmation-modal": SimpleConfirmationModal,
    "standard-input": StandardInput
  }
})
export default class ExportResultsModal extends Vue {
  @Prop() isExportResultsModalVisible!: boolean;
  @Prop() isExportButtonDisabled!: boolean;
  @Prop() isExportInProgress!: boolean;
  @Prop() fileToBeDownloadedName!: string;
  @Prop() handleExportResultsModal!: Function;

  private fileName = this.fileToBeDownloadedName;

  private get isFileNameValid(): boolean {
    return this.fileName.trim().length > 0;
  }

  handleConfirmation() {
    this.$emit("confirmExport", this.fileName);
  }

  @Watch("fileToBeDownloadedName")
  onNameChange() {
    this.fileName = this.fileToBeDownloadedName;
  }
}
