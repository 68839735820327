export function downloadCsvFile(
  docName: string,
  headers: string[],
  headerToEntryLinker: string[],
  entries: any[]
) {
  //define the heading for each row of the data
  const escapedHeaders = headers.map((header: string) => `"${header ?? ""}"`);
  let csv = escapedHeaders.join(",") + "\n";
  const lastLinkerElementIndex = headerToEntryLinker.length - 1;

  //merge the data with CSV
  entries.forEach(entry => {
    let row = "";
    headerToEntryLinker.forEach((link, index) => {
      row += `"${entry[link] ?? ""}"`;
      if (index !== lastLinkerElementIndex) {
        row += ",";
      }
    });
    csv += row + "\n";
  });

  const hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  hiddenElement.target = "_blank";

  //provide the name for the CSV file to be downloaded
  hiddenElement.download = docName.replaceAll(".", "_");
  hiddenElement.click();
}
