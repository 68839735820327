export class PaymentFilterMenuSelection {
  public firstItemSelection: string;
  public secondItemSelection: string;
  public thirdItemSelection: string;
  public startDate: string;
  public endDate: string;

  constructor(
    firstItemSelection: string,
    secondItemSelection: string,
    thirdItemSelection: string,
    startDate: string,
    endDate: string
  ) {
    this.firstItemSelection = firstItemSelection;
    this.secondItemSelection = secondItemSelection;
    this.thirdItemSelection = thirdItemSelection;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
