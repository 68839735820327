

































































import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { InputObject } from "@/models/document-entry/input-object";
import { PaymentFilterMenuSelection } from "@/models/payments/payment-filter-menu-selection";

@Component({
  components: {
    "icon-button": IconButton
  }
})
export default class PaymentsFilterMenu extends Vue {
  @Prop() storedStartDate!: string;
  @Prop() storedEndDate!: string;
  @Prop() firstItemsList!: InputObject[];
  @Prop() secondItemsList!: InputObject[];
  @Prop() thirdItemsList!: InputObject[];
  @Prop() storedFirstSelection!: string;
  @Prop() storedSecondSelection!: string;
  @Prop() storedThirdSelection!: string;
  @Prop() isExecuteSearchDisabled!: boolean;

  private todayDate = moment()
    .format()
    .toString()
    .substring(0, 10);

  private dateRange = {
    startDate: this.storedStartDate,
    endDate: this.storedEndDate
  };
  private firstItemsSelection = this.storedFirstSelection;
  private secondItemsSelection = this.storedSecondSelection;
  private thirdItemsSelection = this.storedThirdSelection;

  private isValid = true;
  private dateRules = [
    (value: string) => !!value || "Date is required",
    (value: string) =>
      Date.parse(this.dateRange.startDate) <=
        Date.parse(this.dateRange.endDate) || "Invalid dates selected",
    (value: string) =>
      Date.parse(this.dateRange.endDate) <= Date.parse(this.todayDate) ||
      "Invalid dates selected"
  ];
  private dateRuleErrorMessages = ["Date is required"];

  validateInputs() {
    (this.$refs["payments-filter-menu"] as any)?.validate();
  }

  handleFormSubmit() {
    this.$emit(
      "filtersChanged",
      new PaymentFilterMenuSelection(
        this.firstItemsSelection,
        this.secondItemsSelection,
        this.thirdItemsSelection,
        this.dateRange.startDate,
        this.dateRange.endDate
      )
    );
  }
}
