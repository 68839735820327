







import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class InputLabelWrapper extends Vue {
  @Prop() conditional!: boolean;
  @Prop() required!: boolean;
  @Prop() title!: string;
  @Prop() disabled!: boolean;
}
