














import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconTextButton extends Vue {
  @Prop() buttonText!: string;
  @Prop() materialIconToDisplay!: string;
  @Prop() handleClick!: Function;
  @Prop() isReadOnly?: boolean;
  @Prop({ default: "" }) customClass?: string;

  private get isButtonInactive() {
    if (this.isReadOnly) {
      return this.isReadOnly;
    }
    return false;
  }
}
