





















import { Vue, Component, Prop } from "vue-property-decorator";
import InputLabel from "./InputLabelWrapper.vue";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class StandardInput extends Vue {
  @Prop() inputValue?: string;
  @Prop() isReadOnly!: boolean;
  @Prop({ default: false }) isRequired!: boolean;
  @Prop() labelText!: string;
  @Prop({ default: "" }) customClass!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: false }) autofocus?: boolean;
  private value = this.inputValue || "";

  change() {
    this.$emit("lookup", this.value);
  }

  emitEnter() {
    this.$emit("emit-enter");
  }
}
