import { app } from "@/main";
import options from "@/shared/constants/toast-options";
import * as Sentry from "@sentry/browser";

// The Catch function takes an error handler function as an argument and returns
// another function, which is a decorator

// a decorator is an expression which returns function and can take as arguments:
//  target: class, where this decorator is actually used;
//  name: method/property name to which this decorator is applied;
//  property descriptor: specific object that describes the property’s attributes.

// Example invocations of the Catch decorator:
// @Catch()
// @Catch(errorHandlerFunctionLikeTheExampleOnNextLine)
// @Catch((error, context) => { context.errorMessage = error.message }) where context
// is the current object: a Vue class component with errorMessage property in this example

function Catch(localHandler?: Function) {
  return function(target, key, descriptor) {
    // save descriptor value
    const originalMethod = descriptor.value;

    // rewrite the original value with our version of the target function,
    // where we run original method with arguments from wrapper function
    descriptor.value = async function(...args) {
      try {
        // use .apply() to link method with an original context (this)
        return await originalMethod.apply(this, args);
      } catch (error) {
        Sentry.captureException(error);

        // check if error handler function is passed to Catch
        if (localHandler) {
          // if so, call the error handler function with the error object and current context (this)
          localHandler.call(null, error, this);
        } else {
          // if not, perform a default error handling action
          app.$toasted.show(
            `<p>Error occurred: ${(error as any).message}</p>`,
            {
              ...options.ERROR_OPTIONS
            }
          );
        }
      }
    };

    return descriptor;
  };
}

export default Catch;
