


























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import { TableColumnSelector } from "@/models/payments/table-column-selector";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "secondary-button": SecondaryButton
  }
})
export default class TableColumnSelectorModal extends Vue {
  @Prop() isModalDisplayed!: boolean;
  @Prop({ default: "" }) modalSubtitle!: string;
  @Prop() defaultTableColumns!: TableColumnSelector[];
  @Prop() availableTableColumns!: TableColumnSelector[];
  @Prop({ default: "40%" }) width?: string;
  @Prop({ default: false }) shouldShowDetailsColumnOption?: boolean;

  private shouldSelectAllColumns = false;
  private tableColumns: TableColumnSelector[] = cloneDeep(
    this.availableTableColumns
  );

  handleConfirmSelection() {
    this.$emit("updateColumns", this.tableColumns);
  }

  handleResetSelection() {
    this.tableColumns = cloneDeep(this.defaultTableColumns);
    this.$emit("updateColumns", this.tableColumns);
  }

  handleCloseModal() {
    // reset modal selections to values before any changes were made
    this.tableColumns = cloneDeep(this.availableTableColumns);
    this.$emit("closeWithoutSaving", this.tableColumns);
  }

  handleSelectAllClicked() {
    if (this.shouldSelectAllColumns) {
      this.tableColumns.forEach(column => {
        column.selected = true;
      });
    } else {
      this.tableColumns.forEach(column => {
        column.selected = false;
      });
    }
  }

  @Watch("tableColumns", { deep: true })
  onTableColumnsChange() {
    if (this.tableColumns.some(column => !column.selected)) {
      this.shouldSelectAllColumns = false;
    }
  }
}
